import React, { useCallback, useEffect, useState } from 'react';
import Table from './subcomponents/Table';
import { TableWithHeader } from 'dyl-components';
import Toolbar from './subcomponents/Toolbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import pbxConfigActions from 'actions/pbx_config';

const OUTSIDE_NUMBERS_URL = "/settings/phone-management/numbers/outside-numbers";

const OutsideNumbers = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }
    
    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`${OUTSIDE_NUMBERS_URL}${query_string ? `?${query_string}` : ''}`,);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${OUTSIDE_NUMBERS_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${OUTSIDE_NUMBERS_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const [filters, setFilters] = useState({
        shared: params.get('shared')?.split(','),
        type: params.get('type')?.split(','),
        user: params.get('user')?.split(','),
        forwarding: params.get('forwarding')?.split(','),
        no_answer_destination: params.get('no_answer_destination')?.split(',')
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    const onReload = useCallback(() => {
    }, [])

    useEffect(() => {
        const query = new URLSearchParams(params);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const { shared, type, user, forwarding, no_answer_destination } = filters;
        if (shared?.length > 0) {
            query.set('shared', shared.join(','));
        } else {
            query.delete('shared');
        }
        if (type?.length > 0) {
            query.set('type', type.join(','));
        } else {
            query.delete('type');
        }
        if (user?.length > 0) {
            query.set('user', user.join(','));
        } else {
            query.delete('user');
        }
        if (forwarding?.length > 0) {
            query.set('forwarding', forwarding.join(','));
        } else {
            query.delete('forwarding');
        }
        if (no_answer_destination?.length > 0) {
            query.set('no_answer_destination', no_answer_destination.join(','));
        } else {
            query.delete('no_answer_destination');
        }
        const query_string = query.toString();
        navigate(`${OUTSIDE_NUMBERS_URL}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    useEffect(() => {
        onReload();
    }, [onReload])

    return (
        <div className='OutsideNumbers'>
            <TableWithHeader
                header={(
                    <Toolbar
                        search={searchQuery}
                        onChangeSearch={onChangeSearch}
                        onSearchSubmit={onSearchSubmit}
                        cancelFunction={cancelFunction}
                    />
                )}
                table={
                    <Table
                        onFilter={onFilter}
                        onPageChange={onPageChange}
                    />
                }
            />
        </div>
    )
}

export default OutsideNumbers;