import React, { useContext, useState } from 'react';
import { Sidebar, Menu, Segment, Transition } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { CallModal } from 'shared/modals';

import ActionBar from './subcomponents/ActionBar';
import TopNavigation from './subcomponents/TopNavigation';
import LeftSidebar from './subcomponents/LeftSidebar';
import SecondarySidebar from './subcomponents/SecondarySidebar';
import RightSidebar from './subcomponents/RightSidebar';

import appActions from 'actions/app/app';

import './index.scss';
import withErrorBoundary from 'shared/ErrorBoundary';
import { SidePanelContext } from 'shared/context/SidePanelProvider';

const Main = ({phone}) => {
    const { isSidePanelOpen: isSidebarOpen, onOpenSidePanel, onCloseSidePanel } = useContext(SidePanelContext);
    const [activeSecondarySidebar, setActiveSecondarySidebar] = useState('');
    const [onEditRecentsSidebar, setOnEditRecentsSidebar] = useState(false);

    const { isAuthenticatingUser, authenticateUserMessage, callIsActive } = useSelector(state => ({
        authenticateUserMessage: state.auth.authenticateUserMessage,
        isAuthenticatingUser: state.auth.isAuthenticatingUser,
        callIsActive: state.call.callIsActive
    }))

    const dispatch = useDispatch();

    const toggleMainMenu = () => {
        dispatch(appActions.toggleMainMenu());
    }

    const [params] = useSearchParams();

    const isRightSidebarOpen = params.get('module_id') !== null;
    const location = useLocation();
    const navigate = useNavigate();

    const onCloseRightSidebar = () => {
        if (isRightSidebarOpen) {
            const query = new URLSearchParams(params);
            query.delete('module_id');
            query.delete('module_tab');
            const query_string = query.toString();
            navigate(`${location.pathname}${query_string ? `?${query_string}` : ''}`, { state: { isTogglingPanel: true } });
        }
    }

    const onToggleMainMenu = () => {
        isSidebarOpen ? onCloseSidePanel() : onOpenSidePanel();
        toggleMainMenu();
    }

    const onToggleEditMode = (name) => {
        if (name === 'onEditFavoritesSidebar') {
            setOnEditRecentsSidebar(!onEditRecentsSidebar);
        }
    }

    const onToggleSecondarySidebar = (name) => {
        setActiveSecondarySidebar(activeSecondarySidebar === name ? '' : name);
    }

    const onCloseSecondarySidebar = () => {
        setActiveSecondarySidebar('');
    }

    const isActiveSecondarySidebar = (name) => {
        return activeSecondarySidebar === name;
    }

    const getSidebarPusherClass = () => {
        if (isSidebarOpen) {
            return `SidebarPusher--${isRightSidebarOpen ? 'open-with-open-right-sidebar' : 'open'}`;
        }
        return `SidebarPusher--${isRightSidebarOpen ? 'closed-with-open-right-sidebar' : 'closed'}`;
    }

    if (!isAuthenticatingUser && authenticateUserMessage !== 'success') {
        return <Navigate to="/login" />;
    }

    return (
        <React.Fragment>
            <div className={`TopNavigationMenu--${isSidebarOpen ? 'squished' : 'full'}`} >
                <TopNavigation isSidebarOpen={isSidebarOpen} onToggleMainMenu={onToggleMainMenu} phone={phone}/>
            </div>
            <Sidebar.Pushable as={Menu} attached className='SidebarPushable'>
                <LeftSidebar
                    isSidebarOpen={isSidebarOpen}
                    onToggleSecondarySidebar={onToggleSecondarySidebar}
                    onCloseSecondarySidebar={onCloseSecondarySidebar}
                    isActiveSecondarySidebar={isActiveSecondarySidebar}
                    onToggleMainMenu={onToggleMainMenu}
                />

                <SecondarySidebar
                    isSidebarOpen={isSidebarOpen}
                    isActiveSecondarySidebar={isActiveSecondarySidebar}
                    onCloseSecondarySidebar={onCloseSecondarySidebar}
                    recents={[]}
                    onEditRecentsSidebar={onEditRecentsSidebar}
                    onToggleEditMode={onToggleEditMode}
                    phone={phone}
                />

                <RightSidebar
                    onClose={onCloseRightSidebar}
                    open={isRightSidebarOpen}
                />

                <Sidebar.Pusher
                    onClick={() => { onCloseSecondarySidebar(); onCloseRightSidebar() }}
                    dimmed={activeSecondarySidebar !== '' || isRightSidebarOpen}
                    className={`SidebarPusher ${getSidebarPusherClass()}`}
                >
                    <Segment loading={isAuthenticatingUser} basic className={`DYL2Main__content DYL2Main__content--${isSidebarOpen ? 'squished' : 'full'} DYL2Main__content--${isRightSidebarOpen ? 'open-right' : 'closed-right'}`}>
                        <div className={`Main__action-bar ${callIsActive ? 'Main__action-bar--active' : ''}`}>
                            <Transition visible={callIsActive} duration='100' unmountOnHide animation='slide down'>
                                <ActionBar />
                            </Transition>
                        </div>
                        <Outlet />
                        <CallModal />
                    </Segment>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </React.Fragment>
    )
}

export default withErrorBoundary(Main);
